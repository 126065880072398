
export default {
  name: "AuthLayout",

  computed: {
    home() {
      return this.localePath(this.$store.state.home_path)
    },

    api_token() {
      return this.$store.getters.api_token
    },
  },

  watch: {
    api_token(v) {
      if (v) this.$router.push(this.home)
    },
  },

  methods: {
    onStorageChange(e) {
      if (this.$store.state.api_token_session) return

      if (e.key == "api_token" && e.newValue) {
        this.$store.commit("SET_API_TOKEN_LOCAL", e.newValue)
      }
    },
  },

  created() {
    if (this.api_token) return this.$router.push(this.home)
    window.addEventListener("storage", this.onStorageChange, false)
  },

  beforeDestroy() {
    window.removeEventListener("storage", this.onStorageChange, false)
  },
}
