import { render, staticRenderFns } from "./mkk-live-item-preview-oneline.vue?vue&type=template&id=61a4ce73"
import script from "./mkk-live-item-preview-oneline.vue?vue&type=script&lang=js"
export * from "./mkk-live-item-preview-oneline.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GsImage: require('/opt/atlassian/pipelines/agent/build/components/gs-image.vue').default,MkkLiveItemStatus: require('/opt/atlassian/pipelines/agent/build/components/mkk-live-item-status.vue').default,MkkPrice: require('/opt/atlassian/pipelines/agent/build/components/mkk-price.vue').default})
