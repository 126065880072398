export default ({ app, $config, query }, inject) => {
  // *** Add feature flags here ***
  //
  // use an array to restrict by environment.
  // e.g. feature: ["staging", "pre-production"]
  // use an map of arrays to restrict by theme, then env.
  // e.g. feature: { nj: ["staging", "pre-production"], }
  //
  // ***

  const flags = {
    negotiations: ["staging", "pre-production", "production"],
    past: ["staging", "pre-production"],
    signup: ["staging", "pre-production", "production"],
    event_categories: ["staging", "pre-production", "production"],
    live_countdown: ["staging", "pre-production", "production"],
    bids_total: {
      nj: ["staging", "production"],
      default: ["staging", "pre-production", "production"],
    },
    bid_confirmation: ["staging", "pre-production", "production"],
    negotiation_confirmation: ["staging", "pre-production", "production"],
    locale_switcher: ["staging", "pre-production", "production"],
    editable_profile: ["staging"],
    collapsible_filters_menu: ["staging", "pre-production", "production"],
    post_negotiations: {
      nj: [],
      default: ["staging"],
    }
  }

  // eventer-restricted features - use eventer_feature()
  const eventers = {
    signup: ["quark", "nj", "mb"],
  }
  // ******************************

  const use = function (feature, options = { dev: $config.appEnv == "development" }) {
    const env = $config.appEnv

    if (env != "production") {
      // override flags from params
      if (feature in query) {
        return query[feature] !== "false"
      }
    }

    // always show in dev
    if (options.dev) return true

    // show features without flag
    if (!(feature in flags)) return true

    const f = flags[feature]
    // show features by matching env
    if (Array.isArray(f)) return f.includes(env)

    // show features by matching theme and env
    if (app.$theme in f) return f[app.$theme].includes(env)
  }

  const use_with_eventer = function (
    feature,
    eventer,
    options = { dev: $config.appEnv == "development" }
  ) {
    if (options.dev) return true

    return use(feature) && (!(feature in eventers) || eventers[feature].includes(eventer))
  }

  inject("feature", use)
  inject("eventer_feature", use_with_eventer)
}
