import debounce from "lodash/debounce"
import { Socket } from "phoenix"

const user_channel_events = [
  "brand:created",
  "brand:updated",
  "brand:deleted",
  "next_item",
  "whole_timeline",
  "app:reload",
  /**
   * These are duplicated here for now, we are moving
   * back to a single user channel called "user" and
   * that channel will handle the distribution of the
   * individual users events. I've got a better way of
   * doing that now so a single channel is more flexible.
   *
   * These events won't do anything yet on this channel
   * then when everyone has got the updated UI version
   * I can switch to broadcasting the messages here
   * instead of the other user channel.
   *
   * This will be more important in the future when
   * we start broadcasting to specific groups of users
   * instead of all users. This is just cleaning up
   * and preparing for that.
   */
  "notification:created",
  "notification:read",
  "notification:count",
  "news:published",
  "negotiation:updated",
  "negotiation:deleted",
]

const my_user_channel_events = [
  "notification:created",
  "notification:read",
  "notification:count",
  "news:published",
  "negotiation:updated",
  "negotiation:deleted",
  "user_token:deleted",
  "user:updated",
]

const live_events_channel_events_live = ["all", "update", "add", "remove"]

const live_events_channel_events_unprefixed = ["next_item", "whole_timeline"]

const events_lifecycle_channel_events = [
  "event:published",
  "event:unpublished",
  "event:expired",
  "event:deleted",
]

export let socket

export const initSocket = async (app, store) => {
  let wstries = 0
  let timer = null
  let live_event_channels = {}

  const firebase_uid = store.state.user.firebase_uid

  const token = app.$api.api_token()

  if (!token) {
    return store.commit("WS_STATE", "closed")
  }

  const site = app.$config.theme == "default" ? "mekiki" : app.$config.theme

  socket = new Socket(app.$config.ws_url, {
    reconnectAfterMs: () => {
      wstries++
      return [10, 50, 100, 150, 200, 250, 500, 1000, 2000][wstries - 1] || 5000
    },
    params: () => {
      return {
        token,
        source: store.state.source,
        ui: store.state.version,
        user_id: store.state?.user?.id,
        site,
      }
    },
  })

  window.socket = socket

  socket.connect()

  /**
   * Global events for all users
   */
  const user_channel = socket.channel("user")
  user_channel.join()

  user_channel_events.forEach((name) => {
    user_channel.on(name, (e) => {
      app.$pubsub.$emit(name, e)
    })
  })

  /**
   * Events just for one user
   */
  const my_user_channel = socket.channel(`user:${firebase_uid}`)
  my_user_channel.join()

  my_user_channel_events.forEach((name) => {
    my_user_channel.on(name, (e) => {
      app.$pubsub.$emit(name, e)
    })
  })

  const live_channel = socket.channel(`live:all`)
  live_channel.join()

  /**
   * Live events channel
   */
  const live_events_channel = socket.channel("live_events")
  live_events_channel.join()

  live_events_channel_events_live.forEach((name) => {
    live_events_channel.on(name, (e) => {
      app.$pubsub.$emit(`live_events:${name}`, e)
    })
  })

  live_events_channel_events_unprefixed.forEach((name) => {
    live_events_channel.on(name, (e) => {
      app.$pubsub.$emit(name, e)
    })
  })

  /**
   * Events lifecycle
   * Live events will still use the `live_events` channel
   */
  const events_lifecycle_channel = socket.channel("events:lifecycle")
  events_lifecycle_channel.join()
  events_lifecycle_channel_events.forEach((name) => {
    events_lifecycle_channel.on(name, (e) => app.$pubsub.$emit(name, e))
  })

  const setWsState = () => {
    store.commit("WS_STATE", socket.connectionState())
  }

  socket.onOpen(() => {
    wstries = 0
    setWsState()
  })

  socket.onError(() => {
    setWsState()
  })

  const disconnect = () => {
    clearTimeout(timer)
    socket.disconnect()
    app.$pubsub.$off("socket_needs_reconnect", reconnect)
  }

  const reconnect = () => {
    socket.disconnect(() => {
      socket.connect()
    })
  }

  const manualCheck = () => {
    clearTimeout(timer)
    setWsState()
    if (!socket.isConnected()) reconnect()
    timer = setTimeout(manualCheck, 5000)
  }

  const liveWatch = (ids) => {
    ids.forEach((id) => {
      const channel = socket.channel(`live_event_count:${id}`)
      channel.join()
      channel.on("count", ({ count }) => {
        store.commit("live/SET_VIEWER_COUNT", { id, count })
      })
      live_event_channels[id] = channel
    })
  }

  const liveUnWatch = (ids) => {
    ids.forEach((id) => {
      if (live_event_channels[id]) {
        const channel = live_event_channels[id]
        channel.leave()
        delete live_event_channels[id]
      }
    })
  }

  app.$pubsub.$on("socket_needs_reconnect", reconnect)
  app.$pubsub.$on("live:watch", liveWatch)
  app.$pubsub.$on("live:unwatch", liveUnWatch)

  timer = setTimeout(manualCheck, 5000)

  return disconnect
}
