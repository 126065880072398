import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4f5df6b4 = () => interopDefault(import('../pages/auction/index.vue' /* webpackChunkName: "pages/auction/index" */))
const _6fa5c0d8 = () => interopDefault(import('../pages/bids.vue' /* webpackChunkName: "pages/bids" */))
const _f334fd00 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _3a7455b3 = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _21a35c2b = () => interopDefault(import('../pages/invoices/index.vue' /* webpackChunkName: "pages/invoices/index" */))
const _a45bd12c = () => interopDefault(import('../pages/likes.vue' /* webpackChunkName: "pages/likes" */))
const _6419876a = () => interopDefault(import('../pages/loading.vue' /* webpackChunkName: "pages/loading" */))
const _500d2497 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _85cc00cc = () => interopDefault(import('../pages/mb/index.vue' /* webpackChunkName: "pages/mb/index" */))
const _1d6ebfa7 = () => interopDefault(import('../pages/memos.vue' /* webpackChunkName: "pages/memos" */))
const _40382080 = () => interopDefault(import('../pages/mypage/index.vue' /* webpackChunkName: "pages/mypage/index" */))
const _c9f1fbd6 = () => interopDefault(import('../pages/news.vue' /* webpackChunkName: "pages/news" */))
const _30ece4d8 = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _6e261908 = () => interopDefault(import('../pages/news/article.vue' /* webpackChunkName: "pages/news/article" */))
const _06ee07ee = () => interopDefault(import('../pages/news/notifications.vue' /* webpackChunkName: "pages/news/notifications" */))
const _e0fde978 = () => interopDefault(import('../pages/past-market-price/index.vue' /* webpackChunkName: "pages/past-market-price/index" */))
const _0abadab0 = () => interopDefault(import('../pages/post-negotiations/index.vue' /* webpackChunkName: "pages/post-negotiations/index" */))
const _13940832 = () => interopDefault(import('../pages/quark/index.vue' /* webpackChunkName: "pages/quark/index" */))
const _01ef7dca = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _041d494c = () => interopDefault(import('../pages/signup.vue' /* webpackChunkName: "pages/signup" */))
const _b5040f06 = () => interopDefault(import('../pages/soon.vue' /* webpackChunkName: "pages/soon" */))
const _30633fb5 = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _45e2f0a8 = () => interopDefault(import('../pages/unsupported_ie.vue' /* webpackChunkName: "pages/unsupported_ie" */))
const _5682e7b7 = () => interopDefault(import('../pages/wait.vue' /* webpackChunkName: "pages/wait" */))
const _062ee559 = () => interopDefault(import('../pages/wins.vue' /* webpackChunkName: "pages/wins" */))
const _d2f375c8 = () => interopDefault(import('../pages/wins/index.vue' /* webpackChunkName: "pages/wins/index" */))
const _a55cc34a = () => interopDefault(import('../pages/wins/held.vue' /* webpackChunkName: "pages/wins/held" */))
const _04f6cf19 = () => interopDefault(import('../pages/wins/pulled-after-held.vue' /* webpackChunkName: "pages/wins/pulled-after-held" */))
const _a39fd7b2 = () => interopDefault(import('../pages/auction/events.vue' /* webpackChunkName: "pages/auction/events" */))
const _f27cba9e = () => interopDefault(import('../pages/mb/login.vue' /* webpackChunkName: "pages/mb/login" */))
const _c466bf00 = () => interopDefault(import('../pages/mb/signup.vue' /* webpackChunkName: "pages/mb/signup" */))
const _063fb5f0 = () => interopDefault(import('../pages/mypage/auction-sales.vue' /* webpackChunkName: "pages/mypage/auction-sales" */))
const _d290b71e = () => interopDefault(import('../pages/mypage/listings.vue' /* webpackChunkName: "pages/mypage/listings" */))
const _701f0f97 = () => interopDefault(import('../pages/mypage/profile.vue' /* webpackChunkName: "pages/mypage/profile" */))
const _61f148da = () => interopDefault(import('../pages/mypage/profile/index.vue' /* webpackChunkName: "pages/mypage/profile/index" */))
const _1075f6a4 = () => interopDefault(import('../pages/mypage/profile/email.vue' /* webpackChunkName: "pages/mypage/profile/email" */))
const _82dea97a = () => interopDefault(import('../pages/mypage/profile/password.vue' /* webpackChunkName: "pages/mypage/profile/password" */))
const _86c4ae6a = () => interopDefault(import('../pages/mypage/profile/settings.vue' /* webpackChunkName: "pages/mypage/profile/settings" */))
const _81b6f168 = () => interopDefault(import('../pages/post-negotiations/apply.vue' /* webpackChunkName: "pages/post-negotiations/apply" */))
const _8044c204 = () => interopDefault(import('../pages/quark/login.vue' /* webpackChunkName: "pages/quark/login" */))
const _ef9fa45a = () => interopDefault(import('../pages/quark/signup.vue' /* webpackChunkName: "pages/quark/signup" */))
const _49653648 = () => interopDefault(import('../pages/verify/email.vue' /* webpackChunkName: "pages/verify/email" */))
const _6434979f = () => interopDefault(import('../pages/auction/_id/index.vue' /* webpackChunkName: "pages/auction/_id/index" */))
const _5b5bc006 = () => interopDefault(import('../pages/past-market-price/_item_id.vue' /* webpackChunkName: "pages/past-market-price/_item_id" */))
const _454cc4a2 = () => interopDefault(import('../pages/auction/_id/live.vue' /* webpackChunkName: "pages/auction/_id/live" */))
const _7f1297da = () => interopDefault(import('../pages/auction/_id/box/_box.vue' /* webpackChunkName: "pages/auction/_id/box/_box" */))
const _4029da82 = () => interopDefault(import('../pages/auction/_id/_item.vue' /* webpackChunkName: "pages/auction/_id/_item" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/auction",
    component: _4f5df6b4,
    name: "auction___ja___default"
  }, {
    path: "/bids",
    component: _6fa5c0d8,
    name: "bids___ja___default"
  }, {
    path: "/en",
    component: _f334fd00,
    name: "index___en"
  }, {
    path: "/forgot-password",
    component: _3a7455b3,
    name: "forgot-password___ja___default"
  }, {
    path: "/invoices",
    component: _21a35c2b,
    name: "invoices___ja___default"
  }, {
    path: "/ja",
    component: _f334fd00,
    name: "index___ja"
  }, {
    path: "/likes",
    component: _a45bd12c,
    name: "likes___ja___default"
  }, {
    path: "/loading",
    component: _6419876a,
    name: "loading___ja___default"
  }, {
    path: "/login",
    component: _500d2497,
    name: "login___ja___default"
  }, {
    path: "/mb",
    component: _85cc00cc,
    name: "mb___ja___default"
  }, {
    path: "/memos",
    component: _1d6ebfa7,
    name: "memos___ja___default"
  }, {
    path: "/mypage",
    component: _40382080,
    name: "mypage___ja___default"
  }, {
    path: "/news",
    component: _c9f1fbd6,
    children: [{
      path: "",
      component: _30ece4d8,
      name: "news___ja___default"
    }, {
      path: "article",
      component: _6e261908,
      name: "news-article___ja___default"
    }, {
      path: "notifications",
      component: _06ee07ee,
      name: "news-notifications___ja___default"
    }]
  }, {
    path: "/past-market-price",
    component: _e0fde978,
    name: "past-market-price___ja___default"
  }, {
    path: "/post-negotiations",
    component: _0abadab0,
    name: "post-negotiations___ja___default"
  }, {
    path: "/quark",
    component: _13940832,
    name: "quark___ja___default"
  }, {
    path: "/reset-password",
    component: _01ef7dca,
    name: "reset-password___ja___default"
  }, {
    path: "/signup",
    component: _041d494c,
    name: "signup___ja___default"
  }, {
    path: "/soon",
    component: _b5040f06,
    name: "soon___ja___default"
  }, {
    path: "/terms",
    component: _30633fb5,
    name: "terms___ja___default"
  }, {
    path: "/unsupported_ie",
    component: _45e2f0a8,
    name: "unsupported_ie___ja___default"
  }, {
    path: "/wait",
    component: _5682e7b7,
    name: "wait___ja___default"
  }, {
    path: "/wins",
    component: _062ee559,
    children: [{
      path: "",
      component: _d2f375c8,
      name: "wins___ja___default"
    }, {
      path: "held",
      component: _a55cc34a,
      name: "wins-held___ja___default"
    }, {
      path: "pulled-after-held",
      component: _04f6cf19,
      name: "wins-pulled-after-held___ja___default"
    }]
  }, {
    path: "/auction/events",
    component: _a39fd7b2,
    name: "auction-events___ja___default"
  }, {
    path: "/en/auction",
    component: _4f5df6b4,
    name: "auction___en"
  }, {
    path: "/en/bids",
    component: _6fa5c0d8,
    name: "bids___en"
  }, {
    path: "/en/forgot-password",
    component: _3a7455b3,
    name: "forgot-password___en"
  }, {
    path: "/en/invoices",
    component: _21a35c2b,
    name: "invoices___en"
  }, {
    path: "/en/likes",
    component: _a45bd12c,
    name: "likes___en"
  }, {
    path: "/en/loading",
    component: _6419876a,
    name: "loading___en"
  }, {
    path: "/en/login",
    component: _500d2497,
    name: "login___en"
  }, {
    path: "/en/mb",
    component: _85cc00cc,
    name: "mb___en"
  }, {
    path: "/en/memos",
    component: _1d6ebfa7,
    name: "memos___en"
  }, {
    path: "/en/mypage",
    component: _40382080,
    name: "mypage___en"
  }, {
    path: "/en/news",
    component: _c9f1fbd6,
    children: [{
      path: "",
      component: _30ece4d8,
      name: "news___en"
    }, {
      path: "article",
      component: _6e261908,
      name: "news-article___en"
    }, {
      path: "notifications",
      component: _06ee07ee,
      name: "news-notifications___en"
    }]
  }, {
    path: "/en/past-market-price",
    component: _e0fde978,
    name: "past-market-price___en"
  }, {
    path: "/en/post-negotiations",
    component: _0abadab0,
    name: "post-negotiations___en"
  }, {
    path: "/en/quark",
    component: _13940832,
    name: "quark___en"
  }, {
    path: "/en/reset-password",
    component: _01ef7dca,
    name: "reset-password___en"
  }, {
    path: "/en/signup",
    component: _041d494c,
    name: "signup___en"
  }, {
    path: "/en/soon",
    component: _b5040f06,
    name: "soon___en"
  }, {
    path: "/en/terms",
    component: _30633fb5,
    name: "terms___en"
  }, {
    path: "/en/unsupported_ie",
    component: _45e2f0a8,
    name: "unsupported_ie___en"
  }, {
    path: "/en/wait",
    component: _5682e7b7,
    name: "wait___en"
  }, {
    path: "/en/wins",
    component: _062ee559,
    children: [{
      path: "",
      component: _d2f375c8,
      name: "wins___en"
    }, {
      path: "held",
      component: _a55cc34a,
      name: "wins-held___en"
    }, {
      path: "pulled-after-held",
      component: _04f6cf19,
      name: "wins-pulled-after-held___en"
    }]
  }, {
    path: "/ja/auction",
    component: _4f5df6b4,
    name: "auction___ja"
  }, {
    path: "/ja/bids",
    component: _6fa5c0d8,
    name: "bids___ja"
  }, {
    path: "/ja/forgot-password",
    component: _3a7455b3,
    name: "forgot-password___ja"
  }, {
    path: "/ja/invoices",
    component: _21a35c2b,
    name: "invoices___ja"
  }, {
    path: "/ja/likes",
    component: _a45bd12c,
    name: "likes___ja"
  }, {
    path: "/ja/loading",
    component: _6419876a,
    name: "loading___ja"
  }, {
    path: "/ja/login",
    component: _500d2497,
    name: "login___ja"
  }, {
    path: "/ja/mb",
    component: _85cc00cc,
    name: "mb___ja"
  }, {
    path: "/ja/memos",
    component: _1d6ebfa7,
    name: "memos___ja"
  }, {
    path: "/ja/mypage",
    component: _40382080,
    name: "mypage___ja"
  }, {
    path: "/ja/news",
    component: _c9f1fbd6,
    children: [{
      path: "",
      component: _30ece4d8,
      name: "news___ja"
    }, {
      path: "article",
      component: _6e261908,
      name: "news-article___ja"
    }, {
      path: "notifications",
      component: _06ee07ee,
      name: "news-notifications___ja"
    }]
  }, {
    path: "/ja/past-market-price",
    component: _e0fde978,
    name: "past-market-price___ja"
  }, {
    path: "/ja/post-negotiations",
    component: _0abadab0,
    name: "post-negotiations___ja"
  }, {
    path: "/ja/quark",
    component: _13940832,
    name: "quark___ja"
  }, {
    path: "/ja/reset-password",
    component: _01ef7dca,
    name: "reset-password___ja"
  }, {
    path: "/ja/signup",
    component: _041d494c,
    name: "signup___ja"
  }, {
    path: "/ja/soon",
    component: _b5040f06,
    name: "soon___ja"
  }, {
    path: "/ja/terms",
    component: _30633fb5,
    name: "terms___ja"
  }, {
    path: "/ja/unsupported_ie",
    component: _45e2f0a8,
    name: "unsupported_ie___ja"
  }, {
    path: "/ja/wait",
    component: _5682e7b7,
    name: "wait___ja"
  }, {
    path: "/ja/wins",
    component: _062ee559,
    children: [{
      path: "",
      component: _d2f375c8,
      name: "wins___ja"
    }, {
      path: "held",
      component: _a55cc34a,
      name: "wins-held___ja"
    }, {
      path: "pulled-after-held",
      component: _04f6cf19,
      name: "wins-pulled-after-held___ja"
    }]
  }, {
    path: "/mb/login",
    component: _f27cba9e,
    name: "mb-login___ja___default"
  }, {
    path: "/mb/signup",
    component: _c466bf00,
    name: "mb-signup___ja___default"
  }, {
    path: "/mypage/auction-sales",
    component: _063fb5f0,
    name: "mypage-auction-sales___ja___default"
  }, {
    path: "/mypage/listings",
    component: _d290b71e,
    name: "mypage-listings___ja___default"
  }, {
    path: "/mypage/profile",
    component: _701f0f97,
    children: [{
      path: "",
      component: _61f148da,
      name: "mypage-profile___ja___default"
    }, {
      path: "email",
      component: _1075f6a4,
      name: "mypage-profile-email___ja___default"
    }, {
      path: "password",
      component: _82dea97a,
      name: "mypage-profile-password___ja___default"
    }, {
      path: "settings",
      component: _86c4ae6a,
      name: "mypage-profile-settings___ja___default"
    }]
  }, {
    path: "/post-negotiations/apply",
    component: _81b6f168,
    name: "post-negotiations-apply___ja___default"
  }, {
    path: "/quark/login",
    component: _8044c204,
    name: "quark-login___ja___default"
  }, {
    path: "/quark/signup",
    component: _ef9fa45a,
    name: "quark-signup___ja___default"
  }, {
    path: "/verify/email",
    component: _49653648,
    name: "verify-email___ja___default"
  }, {
    path: "/en/auction/events",
    component: _a39fd7b2,
    name: "auction-events___en"
  }, {
    path: "/en/mb/login",
    component: _f27cba9e,
    name: "mb-login___en"
  }, {
    path: "/en/mb/signup",
    component: _c466bf00,
    name: "mb-signup___en"
  }, {
    path: "/en/mypage/auction-sales",
    component: _063fb5f0,
    name: "mypage-auction-sales___en"
  }, {
    path: "/en/mypage/listings",
    component: _d290b71e,
    name: "mypage-listings___en"
  }, {
    path: "/en/mypage/profile",
    component: _701f0f97,
    children: [{
      path: "",
      component: _61f148da,
      name: "mypage-profile___en"
    }, {
      path: "email",
      component: _1075f6a4,
      name: "mypage-profile-email___en"
    }, {
      path: "password",
      component: _82dea97a,
      name: "mypage-profile-password___en"
    }, {
      path: "settings",
      component: _86c4ae6a,
      name: "mypage-profile-settings___en"
    }]
  }, {
    path: "/en/post-negotiations/apply",
    component: _81b6f168,
    name: "post-negotiations-apply___en"
  }, {
    path: "/en/quark/login",
    component: _8044c204,
    name: "quark-login___en"
  }, {
    path: "/en/quark/signup",
    component: _ef9fa45a,
    name: "quark-signup___en"
  }, {
    path: "/en/verify/email",
    component: _49653648,
    name: "verify-email___en"
  }, {
    path: "/ja/auction/events",
    component: _a39fd7b2,
    name: "auction-events___ja"
  }, {
    path: "/ja/mb/login",
    component: _f27cba9e,
    name: "mb-login___ja"
  }, {
    path: "/ja/mb/signup",
    component: _c466bf00,
    name: "mb-signup___ja"
  }, {
    path: "/ja/mypage/auction-sales",
    component: _063fb5f0,
    name: "mypage-auction-sales___ja"
  }, {
    path: "/ja/mypage/listings",
    component: _d290b71e,
    name: "mypage-listings___ja"
  }, {
    path: "/ja/mypage/profile",
    component: _701f0f97,
    children: [{
      path: "",
      component: _61f148da,
      name: "mypage-profile___ja"
    }, {
      path: "email",
      component: _1075f6a4,
      name: "mypage-profile-email___ja"
    }, {
      path: "password",
      component: _82dea97a,
      name: "mypage-profile-password___ja"
    }, {
      path: "settings",
      component: _86c4ae6a,
      name: "mypage-profile-settings___ja"
    }]
  }, {
    path: "/ja/post-negotiations/apply",
    component: _81b6f168,
    name: "post-negotiations-apply___ja"
  }, {
    path: "/ja/quark/login",
    component: _8044c204,
    name: "quark-login___ja"
  }, {
    path: "/ja/quark/signup",
    component: _ef9fa45a,
    name: "quark-signup___ja"
  }, {
    path: "/ja/verify/email",
    component: _49653648,
    name: "verify-email___ja"
  }, {
    path: "/",
    component: _f334fd00,
    name: "index___ja___default"
  }, {
    path: "/en/auction/:id",
    component: _6434979f,
    name: "auction-id___en"
  }, {
    path: "/en/past-market-price/:item_id?",
    component: _5b5bc006,
    name: "past-market-price-item_id___en"
  }, {
    path: "/ja/auction/:id",
    component: _6434979f,
    name: "auction-id___ja"
  }, {
    path: "/ja/past-market-price/:item_id?",
    component: _5b5bc006,
    name: "past-market-price-item_id___ja"
  }, {
    path: "/en/auction/:id/live",
    component: _454cc4a2,
    name: "auction-id-live___en"
  }, {
    path: "/ja/auction/:id/live",
    component: _454cc4a2,
    name: "auction-id-live___ja"
  }, {
    path: "/en/auction/:id/box/:box?",
    component: _7f1297da,
    name: "auction-id-box-box___en"
  }, {
    path: "/ja/auction/:id/box/:box?",
    component: _7f1297da,
    name: "auction-id-box-box___ja"
  }, {
    path: "/en/auction/:id/:item",
    component: _4029da82,
    name: "auction-id-item___en"
  }, {
    path: "/ja/auction/:id/:item",
    component: _4029da82,
    name: "auction-id-item___ja"
  }, {
    path: "/auction/:id",
    component: _6434979f,
    name: "auction-id___ja___default"
  }, {
    path: "/past-market-price/:item_id?",
    component: _5b5bc006,
    name: "past-market-price-item_id___ja___default"
  }, {
    path: "/auction/:id/live",
    component: _454cc4a2,
    name: "auction-id-live___ja___default"
  }, {
    path: "/auction/:id/box/:box?",
    component: _7f1297da,
    name: "auction-id-box-box___ja___default"
  }, {
    path: "/auction/:id/:item",
    component: _4029da82,
    name: "auction-id-item___ja___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
