import debounce from "lodash/debounce"
import throttle from "lodash/throttle"
import isEqual from "lodash/isEqual"
import range from "lodash/range"
import sortBy from "lodash/sortBy"
import cloneDeep from "lodash/cloneDeep"
import groupBy from "lodash/groupBy"
import uniqBy from "lodash/uniqBy"
import isEmpty from "lodash/isEmpty"

export default (context, inject) => {
  inject("lodash", {
    debounce,
    throttle,
    isEqual,
    range,
    sortBy,
    cloneDeep,
    groupBy,
    uniqBy,
    isEmpty,
  })
}
