
import AgoraRTC from "agora-rtc-sdk-ng"
import { initSocket } from "~/utils/socket"
import { mapGetters } from "vuex"
import without from "lodash/without"

export default {
  name: "DefaultLayout",

  middleware: "authenticated",

  head() {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
    }
  },

  computed: {
    ...mapGetters({
      authed: "authenticated",
    }),

    liveEvents() {
      return this.$store.getters["live_events/all"]
    },

    live_event_ids() {
      return this.$store.getters["live_events/ids"]
    },

    live_event_ids_str() {
      return this.live_event_ids.join("-")
    },

    liveVideoAvailable() {
      return (
        this.$route.path.includes("/live") &&
        this.liveEvents?.find((e) => e.id == this.$route.params.id)?.has_video
      )
    },

    negotiationModalOpen() {
      return this.$store.state.negotiation.modal_id
    },

    bidModalOpen() {
      return this.$store.state.item.bid_modal_id
    },

    theme() {
      return this.$theme
    },

    affiliate() {
      let stored = this.$store.state.session_affiliate
      if (stored) return stored
      let codes = this.$store.state.user?.eventer_codes
      let affiliate = codes?.find((c) => this.$affiliates.includes(c))
      return affiliate || null
    },
  },

  watch: {
    authed() {
      if (!this.authed) {
        let query = this.affiliate ? { [this.affiliate]: null } : null
        this.$store.commit("CLEAR_SESSION_AFFILIATE")
        this.$router.push({ path: this.localePath("login"), query })
      }
    },

    live_event_ids_str(val, old) {
      val = (val || "")
        .split("-")
        .filter((e) => e != "")
        .map((e) => parseInt(e))

      old = (old || "")
        .split("-")
        .filter((e) => e != "")
        .map((e) => parseInt(e))

      if (old.length) {
        const add = without(val, ...old)
        const rmv = without(old, ...val)
        this.$pubsub.$emit("live:watch", add)
        this.$pubsub.$emit("live:unwatch", rmv)
      } else {
        this.$pubsub.$emit("live:watch", val)
      }
    },
  },

  data() {
    return {
      drawer: false,
      fixed: false,
      title: "",
      ok: false,
      ticker: null,
    }
  },

  methods: {
    async logout(message) {
      this.$store.commit("auction/CLEAR_EVENT_QUERIES")
      await this.$logout()

      if (message) {
        setTimeout(() => {
          this.$store.dispatch("alerts/create", {
            type: "error",
            message,
          })
        }, 500)
      }
    },

    updateItem(data) {
      if (data.reset) {
        this.$pubsub.$emit("item:reset", data)
      }

      this.$store.commit("item/UPDATE_ITEM", data)
      this.$store.commit("autobids/UPDATE_ITEM", data)
      this.clearItemError(data)
    },

    itemError(data) {
      this.$store.commit("item/SET_ITEM_ERR", data)
    },

    clearItemError(data) {
      if (data.status !== 0) {
        this.$store.commit("item/CLEAR_ITEM_ERR", data.id)
      }
    },

    appReload() {
      window.location.reload()
    },

    getUnreadNotificationCount() {
      this.$store.dispatch("getUnreadNotificationCount")
    },

    setUnreadNofiticationCount({ count }) {
      this.$store.commit("SET_UNREAD_NOTIFICATION_COUNT", count)
    },

    getUnreadNewsCount() {
      this.$store.dispatch("getUnreadNewsCount")
    },

    async ping() {
      await this.pingRequest()
      setTimeout(this.pingRequest, 1000 * 60 * 60)
    },

    async pingRequest() {
      const { err } = await this.$store.dispatch("ping", {
        type: "ping",
        user_id: this.$store.state?.user?.id,
      })

      const error = err?.response?.data?.error

      if (error == "Authenticated, but couldn't find user") {
        this.logout(this.$t("error.auth_no_error"))
      }

      if (error == "Authorisation Failed") {
        this.logout(this.$t("error.auth_failed"))
      }
    },

    syncServerTime() {
      let now = +new Date()
      if (this.time && Math.abs(+now - this.time) > 5000) {
        // time has changed!
        this.$store.dispatch("ping", {
          type: "time",
          user_id: this.$store.state?.user?.id,
        })
      }
      this.time = +now
    },

    updateEvent(data) {
      this.$store.dispatch("auction/updateEvent", data)
    },

    setNextItem(data) {
      this.$store.commit("live/SET_NEXT_ITEM", data)
      this.updateItem(data)
    },

    setWholeTimeline(data) {
      this.$store.commit("live/SET_WHOLE_TIMELINE", data)
    },

    interact() {
      // Ensure audio playback is allowed
      if (this.$store.state.live.autoplay_blocked == true) {
        this.$store.dispatch("live/interact")
      }
    },

    updateNegotiation({ id, negotiation }) {
      const item = this.$store.state.item.all[id] || {}
      let negotiations = item.negotiations || []

      // Maybe update if exists
      negotiations = negotiations.map((e) => {
        if (e.id == negotiation.id) {
          return { ...e, ...negotiation }
        } else {
          return e
        }
      })

      // Add if new
      const found = negotiations.find((e) => e.id == negotiation.id)
      if (!found) negotiations = [negotiation, ...negotiations]

      this.updateItem({ id, negotiations, _update_type: "negotiation" })
    },

    deleteNegotiation({ id, negotiation_id }) {
      const item = this.$store.state.item.all[id]

      if (item) {
        let negotiations = item.negotiations

        if (negotiations) {
          negotiations = negotiations.filter((e) => e.id != negotiation_id)
          if (negotiations.length == 0) negotiations = null
          const data = { id, negotiations, _update_type: "negotiation_deleted" }
          this.updateItem(data)
        }
      }
    },

    setLiveEvents({ events }) {
      this.$store.commit("live_events/SET_LIVE_EVENTS", events)
      events.map((event) => this.$store.commit("auction/UPDATE_EVENT", event))
    },

    addLiveEvent(data) {
      this.$store.commit("live_events/ADD_LIVE_EVENT", data)
      this.$store.commit("auction/UPDATE_EVENT", data)
    },

    updateLiveEvent(data) {
      this.$store.commit("live_events/UPDATE_LIVE_EVENT", data)
      this.$store.commit("auction/UPDATE_EVENT", data)
    },

    removeLiveEvent(data) {
      this.$store.commit("live_events/REMOVE_LIVE_EVENT", data)
    },

    insertBrand(payload) {
      this.$store.commit("INSERT_BRAND", payload)
    },

    updateBrand(payload) {
      this.$store.commit("UPDATE_BRAND", payload)
    },

    onStorageChange(e) {
      this.$store.dispatch("storageEvent", e)
    },

    onUserUpdated(data) {
      this.$store.commit("UPDATE_USER", data)
    },
  },

  async created() {
    await this.ping()
    this.ticker = setInterval(this.syncServerTime, 1000)
    this.disconnect = await initSocket(this, this.$store)
    this.ok = true
    this.$pubsub.$on("next_item", this.setNextItem)
    this.$pubsub.$on("whole_timeline", this.setWholeTimeline)
    this.$pubsub.$on("item:updated", this.updateItem)
    this.$pubsub.$on("item:error", this.itemError)
    this.$pubsub.$on("app:reload", this.appReload)
    this.$pubsub.$on("notification:count", this.setUnreadNofiticationCount)
    this.$pubsub.$on("news:published", this.getUnreadNewsCount)
    this.$pubsub.$on("event:updated", this.updateEvent)
    this.$pubsub.$on("auth:error", this.pingRequest)
    this.$pubsub.$on("logout", this.logout)
    this.$pubsub.$on("negotiation:updated", this.updateNegotiation)
    this.$pubsub.$on("negotiation:deleted", this.deleteNegotiation)
    this.$pubsub.$on("live_events:all", this.setLiveEvents)
    this.$pubsub.$on("live_events:add", this.addLiveEvent)
    this.$pubsub.$on("live_events:update", this.updateLiveEvent)
    this.$pubsub.$on("live_events:remove", this.removeLiveEvent)
    this.$pubsub.$on("brand:created", this.insertBrand)
    this.$pubsub.$on("brand:updated", this.updateBrand)
    this.$pubsub.$on("user_token:deleted", this.pingRequest)
    this.$pubsub.$on("user:updated", this.onUserUpdated)
    this.getUnreadNotificationCount()
    this.getUnreadNewsCount()
    AgoraRTC.onAutoplayFailed = () => {
      this.$store.commit("live/BLOCK_AUTOPLAY", true)
    }
    window.addEventListener("storage", this.onStorageChange, false)
    document.addEventListener("click", this.interact)
  },

  mounted() {
    this.$moment.locale(this.$i18n.locale)
  },

  beforeDestroy() {
    if (this.ticker) {
      clearInterval(this.ticker)
      this.ticker = null
    }
    if (this.disconnect) this.disconnect()
    this.$pubsub.$off("next_item", this.setNextItem)
    this.$pubsub.$off("whole_timeline", this.setWholeTimeline)
    this.$pubsub.$off("item:updated", this.updateItem)
    this.$pubsub.$off("item:error", this.itemError)
    this.$pubsub.$off("app:reload", this.appReload)
    this.$pubsub.$off("notification:count", this.setUnreadNofiticationCount)
    this.$pubsub.$off("news:published", this.getUnreadNewsCount)
    this.$pubsub.$off("auth:error", this.pingRequest)
    this.$pubsub.$off("logout", this.logout)
    this.$pubsub.$off("negotiation:updated", this.updateNegotiation)
    this.$pubsub.$off("negotiation:deleted", this.deleteNegotiation)
    this.$pubsub.$off("live_events:all", this.setLiveEvents)
    this.$pubsub.$off("live_events:add", this.addLiveEvent)
    this.$pubsub.$off("live_events:update", this.updateLiveEvent)
    this.$pubsub.$off("live_events:remove", this.removeLiveEvent)
    this.$pubsub.$off("brand:created", this.insertBrand)
    this.$pubsub.$off("brand:updated", this.updateBrand)
    this.$pubsub.$off("user_token:deleted", this.pingRequest)
    this.$pubsub.$off("user:updated", this.onUserUpdated)
    AgoraRTC.onAutoplayFailed = null
    window.removeEventListener("storage", this.onStorageChange, false)
    document.removeEventListener("click", this.interact)
  },
}
